var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.orders),function(order,order_i){return _c('CCard',{key:order.id,style:(_vm.getCardStyle(order)),attrs:{"bodyWrapper":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[(order.has_canceled_items)?_c('router-link',{attrs:{"to":{ name: 'admin.orders.show', params: { order: order.id } },"target":"_blank"}},[_vm._v(" 🚨 ")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'admin.orders.show', params: { order: order.id } },"target":"_blank"}},[_vm._v(" #"+_vm._s(order.id)+" ")]),_c('div',{staticStyle:{"display":"flex"}},[(order.invoice.type=='cash')?_c('img',{staticStyle:{"height":"20px"},attrs:{"src":require("@/assets/img/cash.png")}}):_vm._e(),_vm._v(" "+_vm._s(order.user.full_name))])],1),_c('div',{staticClass:"col-sm-6"},[_vm._v(_vm._s(order.delivery_time))])]),_vm._l((order.sub_orders),function(sub_order,sub_order_i){return _c('div',{key:sub_order.id},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[(sub_order.restaurant_manager_comment !== null)?_c('span',[_vm._v("📩")]):_vm._e(),_vm._v(" "+_vm._s(sub_order.restaurant)+" "+_vm._s(sub_order.sub_total)+"€ / "+_vm._s(sub_order.items.length)+" "),(_vm.couriers.length > 0)?_c('CButton',{staticClass:"ml-2",attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleCouriers(order_i, sub_order_i)}}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1):_vm._e()],1),_c('div',{class:[
            'col-sm-3',
            sub_order.cooking_time_left.includes('-') ? 'timer-red' : ''
          ]},[(_vm.couriers.length > 0)?[_vm._v(" "+_vm._s(sub_order.cooking_time_left)+" ")]:_vm._e()],2),(sub_order.courier)?_c('div',{staticClass:"col-sm-3 d-flex align-items-baseline"},[_c('div',{staticClass:"courier-rect",style:({ 'background-color': sub_order.courier.colour })}),_vm._v(" "+_vm._s(sub_order.courier.name)+" ")]):_vm._e()]),_c('CCollapse',{attrs:{"show":Boolean(sub_order._toggled)}},[_c('CCardBody',[_c('CInputRadioGroup',{attrs:{"options":_vm.formatCouriers,"checked":_vm.getCheckedCourier(sub_order)},on:{"update:checked":function($event){return _vm.saveCourier(sub_order, $event)}}})],1)],1)],1)}),_c('div',[_c('a',{staticClass:"btn_book",attrs:{"href":'//www.google.com/maps/dir/' +
            order.restaurantsAddr +
            '/' +
            order.address.street +
            '/',"target":"_blank"}},[_vm._v(_vm._s(order.address.short)+" ")])])],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }