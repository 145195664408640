<template>
  <div>
    <h5>{{ title }}</h5>
    <CCard
      bodyWrapper
      :style="getCardStyle(sub_order)"
      v-for="sub_order in sub_orders"
      :key="sub_order.id"
    >
      <div class="row">
        <div class="col-sm-6">
          <router-link
            :to="{
              name: 'admin.orders.show',
              params: { order: sub_order.order_id }
            }"
            target="_blank"
            v-if="sub_order.order_has_canceled_items"
          >
            🚨
          </router-link>
          <router-link
            :to="{
              name: 'admin.orders.show',
              params: { order: sub_order.order_id }
            }"
            target="_blank"
          >
            #{{ sub_order.order_id }}
          </router-link>
          <!--{{ sub_order.user.full_name }} -->
        </div>
        <!--<div class="col-sm-6">{{ sub_order.delivery_time }}</div> -->
      </div>
      <!--
	  <div class="row">
        <div class="col-sm-6">
          <span v-if="sub_order.restaurant_manager_comment !== null">📩</span>
          {{ sub_order.restaurant }}
        </div>
        <div
          :class="[
            'col-sm-3',
            sub_order.cooking_time_left.includes('-') ? 'timer-red' : ''
          ]"
        >
          {{ sub_order.cooking_time_left }}
        </div>
        <div class="col-sm-3 d-flex align-items-baseline">
          <div
            class="courier-rect"
            :style="{ 'background-color': sub_order.courier.colour }"
          ></div>
          {{ sub_order.courier.name }}
        </div>
      </div>
	  -->
      <div>
        {{ sub_order.address.short }}
      </div>
    </CCard>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    initialSubOrders: {
      required: true
    },
    intervals: {
      required: true
    }
  },

  data() {
    return {
      sub_orders: []
    };
  },

  watch: {
    initialSubOrders: function(newVal) {
      this.sub_orders = newVal;
    }
  },

  methods: {
    getCardStyle(sub_order) {
      const interval = this.intervals.find(
        interval => interval.to === sub_order.delivery_time_simple.split("-")[1]
      );
      const colour = interval ? interval.colour : "#ffffff";

      return { borderLeftColor: colour, borderLeftWidth: "8px" };
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 0.7rem;
}
.courier-rect {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.timer-red {
  color: #ff3939;
}
</style>
