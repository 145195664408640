<template>
  <div>
    <h5>{{ title }}</h5>
    <CCard
      bodyWrapper
      :style="getCardStyle(order)"
      v-for="(order, order_i) in orders"
      :key="order.id"
    >
      <div class="row">
        <div class="col-sm-6">
          <router-link
            :to="{ name: 'admin.orders.show', params: { order: order.id } }"
            target="_blank"
            v-if="order.has_canceled_items"
          >
            🚨
          </router-link>
          <router-link
            :to="{ name: 'admin.orders.show', params: { order: order.id } }"
            target="_blank"
          >
            #{{ order.id }}
          </router-link>
          <div style="display: flex;">
            <img v-if="order.invoice.type=='cash'" style="height: 20px;" src="@/assets/img/cash.png" /> {{ order.user.full_name }}</div>
        </div>
        <div class="col-sm-6">{{ order.delivery_time }}</div>
      </div>
      <div
        v-for="(sub_order, sub_order_i) in order.sub_orders"
        :key="sub_order.id"
      >
        <div class="row">
          <div class="col-sm-6">
            <span v-if="sub_order.restaurant_manager_comment !== null">📩</span>
            {{ sub_order.restaurant }} {{ sub_order.sub_total }}&euro; /
            {{ sub_order.items.length }}
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              class="ml-2"
              @click="toggleCouriers(order_i, sub_order_i)"
              v-if="couriers.length > 0"
            >
              <CIcon name="cilPencil" />
            </CButton>
          </div>
          <div
            :class="[
              'col-sm-3',
              sub_order.cooking_time_left.includes('-') ? 'timer-red' : ''
            ]"
          >
            <template v-if="couriers.length > 0">
              {{ sub_order.cooking_time_left }}
            </template>
          </div>
          <div
            class="col-sm-3 d-flex align-items-baseline"
            v-if="sub_order.courier"
          >
            <div
              class="courier-rect"
              :style="{ 'background-color': sub_order.courier.colour }"
            ></div>
            {{ sub_order.courier.name }}
          </div>
        </div>
        <CCollapse :show="Boolean(sub_order._toggled)">
          <CCardBody>
            <CInputRadioGroup
              :options="formatCouriers"
              :checked="getCheckedCourier(sub_order)"
              @update:checked="saveCourier(sub_order, $event)"
            />
          </CCardBody>
        </CCollapse>
      </div>
      <div>
        <a
          :href="
            '//www.google.com/maps/dir/' +
              order.restaurantsAddr +
              '/' +
              order.address.street +
              '/'
          "
          target="_blank"
          class="btn_book"
          >{{ order.address.short }}
        </a>
      </div>
    </CCard>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    initialOrders: {
      required: true
    },
    intervals: {
      required: true
    },
    couriers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      orders: []
    };
  },

  computed: {
    formatCouriers() {
      const couriers = this.couriers.map(courier => {
        return { label: courier.name, value: courier.id.toString() };
      });

      return [{ label: this.$t("manager.map.none"), value: null }, ...couriers];
    }
  },

  watch: {
    initialOrders: function(newVal) {
      this.orders = newVal;
    }
  },

  methods: {
    toggleCouriers(order_i, sub_order_i) {
      const sub_order = this.orders[order_i].sub_orders[sub_order_i];

      this.$set(sub_order, "_toggled", !sub_order._toggled);
    },
    getCheckedCourier(sub_order) {
      return sub_order.courier ? sub_order.courier.id.toString() : null;
    },
    async saveCourier(sub_order, courier_id) {
      await this.$http.put(`/api/manager/sub-orders/${sub_order.id}`, {
        courier_id
      });
      this.$emit("get-data");
    },
    getCardStyle(order) {
      const interval = this.intervals.find(
        interval => interval.to === order.deliver_to
      );
      const colour = interval ? interval.colour : "#ffffff";

      return { borderLeftColor: colour, borderLeftWidth: "8px" };
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 0.7rem;
}
.courier-rect {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.timer-red {
  color: #ff3939;
}
</style>
