<template>
  <div>
    <CRow class="mb-4">
      <CCol sm="12" class="text-center">
        <div
          class="d-inline-flex flex-column mx-3"
          v-for="item in upcoming_intervals"
          :key="item.restaurant"
        >
          {{ item.restaurant }}
          <div class="d-flex justify-content-center">
            <div
              class="interval-box"
              :style="{ 'background-color': load.interval.colour }"
              v-for="(load, index) in item.load"
              :key="index"
            >
              {{ load.quantity }}
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="2">
        <h5>{{ $t("manager.couriers.title") }}</h5>
        <CourierOrdersCards
          :title="courier.name"
          :initial-sub-orders="getCourierSubOrders(courier)"
          :intervals="intervals"
          v-for="courier in couriers"
          :key="courier.id"
        />
      </CCol>
      <CCol sm="2">
        <OrdersCards
          :title="$t('manager.orders.sent.title')"
          :initial-orders="orders.confirmation"
          :intervals="intervals"
          @get-data="getData"
        />
      </CCol>
      <CCol sm="2">
        <OrdersCards
          :title="$t('manager.orders.confirmed.title')"
          :initial-orders="orders.cooking"
          :intervals="intervals"
          :couriers="couriers_assign"
          @get-data="getData"
        />
      </CCol>
      <CCol sm="2">
        <OrdersCards
            :title="$t('manager.orders.assigned.title')"
            :initial-orders="orders.assigned"
            :intervals="intervals"
            :couriers="couriers_assign"
            @get-data="getData"
        />
      </CCol>
      <CCol sm="2">
        <OrdersCards
          :title="$t('manager.orders.in_delivery.title')"
          :initial-orders="orders.delivery"
          :intervals="intervals"
          @get-data="getData"
        />
      </CCol>
      <CCol sm="2">
        <OrdersCards
          :title="$t('manager.orders.finished.title')"
          :initial-orders="orders.finished"
          :intervals="intervals"
          @get-data="getData"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import OrdersCards from "@/components/manager/OrdersCards";
import CourierOrdersCards from "@/components/manager/CourierOrdersCards";

export default {
  components: {
    OrdersCards,
    CourierOrdersCards
  },

  data() {
    return {
      orders: {},
      couriers: [],
      intervals: [],
      upcoming_intervals: []
    };
  },

  methods: {
    getData() {
      this.$http.get("/api/manager/orders").then(response => {
        this.orders = response.data.data;
        this.couriers = response.data.meta.couriers;
        this.couriers_assign = response.data.meta.couriers_assign;
        this.intervals = response.data.meta.intervals;
        this.upcoming_intervals = response.data.meta.upcoming_intervals;
      });
    },
    getCourierSubOrders(courier) {
      return this.couriers.find(c => c.id === courier.id).sub_orders;
    }
  },

  metaInfo() {
    return {
      title: this.$t("manager.map.title")
    };
  }
};
</script>

<style scoped>
.interval-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin: 0 5px;
  font-weight: bold;
  color: #ffffff;
}
</style>
