import { render, staticRenderFns } from "./OrdersCards.vue?vue&type=template&id=60e5090f&scoped=true"
import script from "./OrdersCards.vue?vue&type=script&lang=js"
export * from "./OrdersCards.vue?vue&type=script&lang=js"
import style0 from "./OrdersCards.vue?vue&type=style&index=0&id=60e5090f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e5090f",
  null
  
)

export default component.exports