var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.sub_orders),function(sub_order){return _c('CCard',{key:sub_order.id,style:(_vm.getCardStyle(sub_order)),attrs:{"bodyWrapper":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[(sub_order.order_has_canceled_items)?_c('router-link',{attrs:{"to":{
              name: 'admin.orders.show',
              params: { order: sub_order.order_id }
            },"target":"_blank"}},[_vm._v(" 🚨 ")]):_vm._e(),_c('router-link',{attrs:{"to":{
              name: 'admin.orders.show',
              params: { order: sub_order.order_id }
            },"target":"_blank"}},[_vm._v(" #"+_vm._s(sub_order.order_id)+" ")])],1)]),_c('div',[_vm._v(" "+_vm._s(sub_order.address.short)+" ")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }